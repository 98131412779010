.bg-gradient-orangexx{
    background-image: linear-gradient(120deg, #f6d365 0%, #fda085 100%);
    /* border-color: orange; */
}
.bg-gradient-orangexxz{
     /* can be treated like a fallback */
 background-color: orange;
 border: none !important;
 -webkit-box-shadow: 0px 0px 67px 3px rgba(255,250,158,0.59);
-moz-box-shadow: 0px 0px 67px 3px rgba(255,250,158,0.59);
box-shadow: 0px 0px 60px 3px rgba(255,250,158,0.59);
}