
.textTx{
   text-overflow: ellipsis;
   white-space: nowrap;
   overflow: hidden;
   color: white;
   font-weight: 500;
}
.shadow-card-dark{
   box-shadow: rgb(0 0 0 / 24%) 0px 0px 2px 0px, rgb(0 0 0 / 24%) 0px 16px 32px -4px;
}
.ReactTable .rt-thead .rt-tr {
   text-align: center;
   height: 60px;
   align-items: center;
   display: flex;
   color: rgb(145, 158, 171);
   font-weight: bold;
   background-color: rgba(145, 158, 171, 0.16);
   border-radius: 10px;
   font-size: 14px;
}
.ReactTable .rt-tr{
   height: 60px;
   display: flex;
   align-items: center;
   color: white;
   font-size: 14px;
}
.ReactTable .rt-tbody .rt-td:last-child{
   text-align: center;
}
.ReactTable .rt-tbody .rt-td{
   text-align: center;
}
.ReactTable .rt-tbody .rt-td:first-child{
   text-align: start;
}
.ReactTable{
   border: none  !important;
}
.btn-gradient-info{
   /* background: linear-gradient(279.87deg, #23E7EA -30.74%, #1DC0DD -22.26%, #1FC9DF -6.02%, #147FC6 -1.19%, #074883 50.3%, #004185 61.14%, #063A75 85.13%);
background-blend-mode: hard-light; */
box-shadow: rgb(24 144 255 / 24%) 0px 8px 16px 0px;
border: none;
/* color: white; */
}
.ReactTable .rt-thead .rt-tr :first-child{
   text-align: left;
}
.ReactTable .rt-thead .rt-tr :last-child{
   text-align: center;
}
.bg-dark-modal{
   background-color: rgb(33, 43, 54);
}
.overflow-hidden{
   overflow: hidden  !important;
}
.modal-content{
   background-color: transparent !important;
}