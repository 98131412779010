.memberName{
    font-style: normal;
    font-weight: bold;
    font-size: 20px;
    line-height: 33px;
    /* identical to box height */
    
    text-align: left;
    
    /* Blue / Blue 0 */
    
    color: #0984e3;
    margin-top: 10px;
    }
    .memberDescription{
        overflow: hidden;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
       font-weight: 500;
    }
    .textThreedots{
        display: block;
        display: -webkit-box;
        max-width: 100%;
        -webkit-line-clamp: 1;
        -webkit-box-orient: vertical;
        overflow: hidden;
        text-overflow: ellipsis;
    }
    .noneBorder{
        border: none;
    }