.fullscreen-yearend{
    height: 100vh;
    width: 100vw;
}
.bg-year-end{
    background-image: radial-gradient(circle 248px at center, #16d9e3 0%, #30c7ec 47%, #46aef7 100%);
    height: 50vh;
}
.bg-loading{
    height: 100vh;
    width: 100vw;
    background-image: url("../../assets/img/bg-year-end.png");
    background-size: contain;
    background-repeat: no-repeat;
    background-color: #1f1f36;
    background-position: center;
}
.logo-vbc{
    height: 50px;
}
.progress-bar{
    background-color: #1f1f36;
}
.bar-progress-end{
    position: absolute;
    bottom: 130px;
    left: 0;
    width: 100%;
}
.rect-progress-bar-percent{
    display: none !important;
}
.mt-form-submit{
    margin-top: -13rem;
}
.mt-form-result{
    margin-top: -18rem;
}
.card-bottom-page{
    position: absolute;
    bottom: 0;
    right: 0;
    width: 100vw;
    justify-content: flex-end;
    display: flex;
    width: 100%;
}
.contain-card-form{
    height: 100vh;
}
.bka-background{
    background-image: url('https://res.cloudinary.com/agridential/image/upload/v1619238725/AGDImage/Frame_1_3x_zxztkh.jpg');
    height: 100vh;
    width: 100vw;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    display: flex;
    justify-content: center;
    align-items: center;
}
.text-bka{
    font-size: 50px;
    color: #f5a835 !important;
}
.wrapper-bka{
    margin-bottom: 175px;
    border-radius: 20px;
   
}