.react-datetime-picker__wrapper{
    width: 100%;
    box-shadow: 0 1px 3px rgba(50, 50, 93, 0.15), 0 1px 0 rgba(0, 0, 0, 0.02) !important;
    border: 0 !important;
    background-color: white;
    transition: box-shadow .15s ease;
    font-size: 0.875rem !important;
    color: #8898aa !important;
    padding: 10px !important;
    border-radius: 0.375rem;
}
.react-datetime-picker{
    width: 100%;
}
.react-datetime-picker__inputGroup__input{
    width: 20% !important;
    text-align: center;
}
.react-datetime-picker__inputGroup__input:invalid{
    background-color: #f6f9fc !important ;
}
.react-datetime-picker__inputGroup{
    text-align: center;
}
.react-datetime-picker__calendar .react-calendar {
    border: 0 solid rgba(0, 0, 0, 0.15) !important;
    border-radius: 0.4375rem !important;
    box-shadow: 0 50px 100px rgba(50, 50, 93, 0.1), 0 15px 35px rgba(50, 50, 93, 0.15), 0 5px 15px rgba(0, 0, 0, 0.1) !important;
}
.react-calendar__month-view__days__day--weekend{
    color: #1171ef !important;
}
.react-calendar__month-view__days__day--weekend:hover{
    color: #1171ef !important;
}
.react-calendar__tile--now{
    color: white !important;
    background-color: #1171ef;
    border-radius: 0.375rem;
}
.react-calendar__tile--active{
    color: white !important;
    background-color: #1171ef;
    border-radius: 0.375rem;
}
.react-calendar__tile:hover{
    border-radius: 0.375rem;
}