.haveAccount{
    margin-top: 25px;
    font-weight: 500;
    color: #d2d2d2;
}
.loginText{
    font-weight: 700;
    color: #0984e3;
    cursor: pointer;
}
