.dzu-dropzone::-webkit-scrollbar {
    display: none;
}
  
  /* Hide scrollbar for IE and Edge */
.dzu-dropzone {
    -ms-overflow-style: none;
}
.dzu-inputLabel{
    font-size: 18px !important;
}
.imageList{
    height: 40px;
    border-radius: 5px;
}
.alignItems{
    display: flex;
    padding: 40px 3%;
    border-bottom: 1px solid #ECECEC;
    align-items: center;
}
.containerList{
    max-height: 262px;
    overflow: scroll;
}
.containerList::-webkit-scrollbar {
    display: none;
}
  
  /* Hide scrollbar for IE and Edge */
.containerList {
    -ms-overflow-style: none;
}
.flexStart{
    width: 50%;
    text-align: start;
}
.flexEnd{
    width: 50%;
    text-align: end;
}